/* global GLOBAL_CONFIG */
import { CMSDialog, useDialogStore, useSocketConnected, useSockets } from '@editor/ui-utils';
import type { Socket } from 'socket.io-client';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

interface IServerToClientEvents {
  handshakeReply: (handshake: { serverVersion: string; forceUpdate: boolean; softUpdate: boolean }) => void;
}

interface IClientToServerEvents {
  handshake: (clientVersion: string) => void;
}

export default function usePing(): void {
  let lastNotifiedVersion: string;

  const { t } = useI18n();
  const { showDialog } = useDialogStore();
  // dialogs using the same ID prevents them from overlapping, each showDialog call just updates the dialog options
  const VersionUpdateDialogId: 'version-update-dialog' = 'version-update-dialog';
  const forcedReloadDialog = new CMSDialog(
    {
      message: t('pingApi.forceUpdate'),
      ok: t('pingApi.reload'),
      cancel: false,
      persistent: true,
    },
    VersionUpdateDialogId,
  );
  const softReloadDialog = new CMSDialog(
    {
      message: t('pingApi.softUpdate'),
      ok: t('pingApi.reload'),
      cancel: t('pingApi.softUpdateCancel'),
      persistent: false,
    },
    VersionUpdateDialogId,
  );

  watch(
    useSocketConnected(),
    (connected) => {
      if (!connected) return;
      const io = useSockets();
      const pingChannel: Socket<IServerToClientEvents, IClientToServerEvents> = io.socket('/ping');
      pingChannel.on('handshakeReply', async ({ serverVersion, forceUpdate, softUpdate }) => {
        if (lastNotifiedVersion !== serverVersion || forceUpdate) {
          if (forceUpdate) {
            showDialog(forcedReloadDialog).onDismiss(() => window.location.reload());
            return;
          }

          if (softUpdate) {
            showDialog(softReloadDialog)
              .onOk(() => window.location.reload())
              .onCancel(() => (lastNotifiedVersion = serverVersion));
          }
        }
      });
      pingChannel.on('connect', () => {
        pingChannel.emit('handshake', GLOBAL_CONFIG.VERSION);
      });
    },
    { immediate: true },
  );
}
